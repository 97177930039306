import React from "react";
import "./icons.scss";
import { urlFor } from "../utils/utils";
import { App } from "../globals";

interface Props {
  apps: App[];
  className?: string;
  invert?: boolean;
}

export const Icons = ({ invert, apps, className }: Props) => {
  return (
    <div className={`item--icons ${className}`}>
      {apps &&
        apps.map((app, i) => (
          <img
            key={i}
            src={urlFor(
              invert && app.inverticon
                ? app.inverticon.asset.url
                : app.icon.asset.url
            ).url()}
            className="item--icon"
            alt={app.name}
          />
        ))}
    </div>
  );
};
